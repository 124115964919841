@font-face {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins-Regular'), 
          url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat-ExtraBold'), 
          url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova-Medium';
    font-style: normal;
    font-weight: 600;
    src: local('ProximaNova-Medium'), 
          url(../fonts/ProximaNova/ProximaNova-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('ProximaNova-Bold'), 
          url(../fonts/ProximaNova/ProximaNova-Bold.ttf) format('truetype');
}

.RestaurantsCoverSuperContainer{
    position: relative;
}

.RestaurantsCoverContainer{
    overflow: hidden;
    max-height: 480px;
}

.RestaurantsCoverImage{
    width: 100vw;
}

.RestaurantsCoverBlur{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 40;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 1)
    );
}

.RestaurantDetailsLeftContainer{
    z-index: 999;
    margin: 0 auto; /* Adjust this value to move the positioned div up and down */
    width: 84%;
    color: #FFF;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 3px 40px #00000017;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    transform: translateY(-40px);
}

.RestaurantDetailsLeftContainerFirst{
    flex: 1;
}

.RestaurantName{
    font-family: 'Poppins-Bold';
    font-size: 2vw;
    color: black;
}

.RestaurantDescription{
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #EA5B2F;
}

.RestaurantDetails{
    font-family: 'Poppins-Regular';
    font-size: 12px;
    width: 100%;
    font-weight: 600;
    margin-top: 5px;
    color: #594800;
}

.RestaurantDetailsRightContainer{
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    bottom: 24%;
    right: 10%; /* Adjust this value to move the positioned div up and down */
    width: 30%;
    color: #FFF;
}

.RestaurantContact{
    border: 1px solid #FFF8B5;
    border-radius: 20px;
    padding: 8px 30px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    cursor: pointer;
    margin-top: 15px;
    color: #EA5B2F;
    background-color: #FFF8B5;
    max-width: 190px;
}

.RestaurantContact:hover{
    background-color: unset;
    color: #EA5B2F;
    border: 1px solid #EA5B2F;
}

.BestDishSuperContainer{
    margin-bottom: 30px;
    padding: 0px 1vw;
}

.BestDishContainer{
    height: 320px;
    display: flex;
    flex-direction: row;
    /* background-color: #FFF; */
    /* border-radius: 15px; */
    /* box-shadow: 3px 3px 6px #00000029; */
    position: relative;
}

.BestDishDetailsColumn{
    /* height: 270px; */
    display: flex;
    flex-direction: column;
}

.BestDishImageContainer{
    width: 32vw;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
}

.BestDishImage{
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.BestDishLikeButtonContainerSuper{
    position: absolute;
    z-index: 100;
    margin: 0 auto;
    opacity: 1.2;
    top: 10px;
    width: 40px;
    right: 5px; /* Adjust this value to move the positioned div up and down */
    color: #FFF;
    /* display: inline-block; */
    display: flex;
    cursor: pointer;
    float: right;
    margin-right: 30px;
}

.BestDishDescription{
    width: 40vw;
    padding: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
}

.BestDishDescriptionOverlay{
    width: 82.5vw !important;
}

.BestDishRatingsContainer{
    color: white;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'ProximaNova-Regular';
    display:inline-block;
}

.BestDishRatingsTitle{
    background: transparent linear-gradient(270deg, #83D48800 0%, #4BA150 60%) 0% 0% no-repeat padding-box;
    padding: 2px 50px 2px 10px;
    font-family: 'Poppins-Medium';
}

.BestDishName{
    font-family: 'ProximaNova-Medium';
    font-size: 20px;
    flex: 1;
    /* margin-left: 10px; */
    /* margin-top: 20px; */
}

.BestDishNameTitle{
    background-color: #EA5B2F;
    border-radius: 12px;
    margin-right: 25px;
    color: white;
    padding: 5px 20px 8px 18px;
    border-radius: 20px;
    font-family: 'Bree-Regular';
    font-size: 17px;
}

.BestDishRedDot, .NonRatedDishGreenDot{
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: red;
    margin-right: 10px;
    border: 2px solid white;
    outline: 2px solid red;
    transform: translateY(1px);
}

.NonRatedDishGreenDot{
    height: 7px;
    width: 7px;
    background-color: #00A73D;
    outline: 2px solid #00A73D;
}

.BestDishCost{
    font-family: 'ProximaNova-Regular';
}

.BestDishRestaurant{
    font-family: 'ProximaNova-Regular';
    font-size: 15px;
    color: #EA5B2F;
    margin-bottom: 5px;
    /* margin-left: 15px; */
    /* margin-left: 30px; */
}

.BestDishDetails{
    flex: 1;
    margin: 20px 30px;
    overflow: scroll;
    font-family: 'ProximaNova-Regular';
    font-size: 15px;
    margin-left: 0px;
    -webkit-line-clamp: 6;
    margin-top: 20px !important;
}

.BestDishDetails::-webkit-scrollbar{
    width: 0px;
}

.BestDishCardActions {
    position: relative;
    /* height: 50px; */
    /* transform: translateY(8px); */
}

.BestDishNameRow{
    display: flex;
    flex-direction: row;
}

.BestDishCardActionsAfterContainer {
    content: '';
    width: 150px;
    height: 3px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    background: #FFEC36;
    bottom: 0px;
    transition: 0.5s ease;
    position: absolute;
    z-index: 400;
}

.NonRatedDishContainer{
    padding-top: 10px;
    padding-bottom: 50px;
}

.NonRatedDishTitle{
    padding: 0px 7vw;
    font-family: 'Bree-SemiBold';
    font-size: 24px;
    margin-bottom: 20px;
    color: #EA5B2F;
}

.NonRatedDishList{
    padding: 0px 7vw;
}

.NonRatedDishItem{
    width: 100%;
    padding: 0px 0px 5px 0px;
    margin: 5px 30px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid rgba(169,169,169,0.4);
}

.NonRatedLikeButton{
    transform: translateY(10px) !important;
}

.NonRatedDishInfo{
    flex: 1;
    margin: 6px 0px;
    margin-right: 5px;
}

.NonRatedRatings{
    font-size: 16px !important;
    margin-right: 5px;
    transform: translateY(3px);
    color: #FAE300;
}

.NonRatedDishName{
    font-family: 'Poppins-Bold';
    font-size: 14px;
}

.NonRatedDishCost{
    font-family: 'Poppins-Medium';
    font-size: 12px;
    color: #5E4B45;
}

.NonRatedDishLikeButtonContainerSuper{
    z-index: 100;
    margin: 0 auto;
    opacity: 1.2;
    width: 40px; /* Adjust this value to move the positioned div up and down */
    color: #FFF;
    /* display: inline-block; */
    display: flex;
    cursor: pointer;
    float: right;
    margin-right: 00px;
    transform: translateY(20px);
}

.NonRatedDishRateButton{
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid #464646;
    border-radius: 5px;
    padding: 5px 30px;
    margin-top: 18px;
    margin-left: 10px;
    font-family: 'Poppins-SemiBold';
    cursor: pointer;
    font-size: 14px;
}

.FilterItemsSuper{
    border-radius: 0px;
}

.BestDishCardActionsContainer{
    transform: translateY(20px);
}

.BestDishRibbon{
    background-color: #3356AA !important;
    transform: translate(-5px, -10px);
    border-radius: 3px 3px 3px 0px;
}

.Ribbon:before {
    border-top: 10px solid #3356AA !important;
  }

@media (max-width: 500px) {
    .RestaurantDetailsLeftContainer{
        width: 90%;
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        left: unset;
        align-items: left;
        bottom: -90%;
    }

    .RestaurantDetailsLeftContainerFirst{
        flex: unset;
    }

    .BestDishImage{
        height: 120%;
    }

    .RestaurantName{
        font-size: 18px;
        text-align: left;
    }

    .RestaurantDescription{
        width: unset;
        font-size: 12px;
        text-align: left;
    }

    .RestaurantDetails{
        width: unset;
        font-size: 12px;
        text-align: left;
    }

    .RestaurantContact{
        font-size: 13px;
        width: 145px;
        text-align: center;
        padding: 8px 15px;
    }

    .FilterItemsText{
        font-size: 12px !important;
        padding: 8px 0px !important;
    }

    .FilterRestaurantItemsText{
        font-size: 12px !important;
        padding: 8px 20px !important;
    }

    .BestDishContainer{
        flex-direction: column;
        height: unset;
    }

    .BestDishDescription, .BestDishImageContainer{
        width: 86vw;
        /* height: 240px; */
        /* flex: 1; */
    }

    .BestDishImageContainer{
        max-height: 320px;
    }

    .BestDishImage{
        overflow: hidden;
    }

    .BestDishSuperContainer{
        margin-bottom: 0px;
    }

    .NonRatedDishDetails{
        display: flex;
        overflow: visible;
        width: 150%;
    }

    .NonRatedDishCost{
        transform: translateY(5px);
    }

    .RestaurantsCoverImage{
        width: 100vw;
        transform: translateY(0%);
    }

    .RestaurantDetailsLeftContainer{
        text-align: center;
    }

    .NonRatedLikeButton{
        /* width: 50px !important;
        height: 50px !important;
        background-size: 1900% !important;
        transform: translateY(30px) !important; */
        margin-right: 0px !important;
    }

    .NonRatedDishRateButton{
        margin-left: 0px;
    }

    .BestDishNameTitle{
        margin-right: 10px;
        padding: 3px 12px 8px 12px;
        font-size: 12px;
        height: 30px;
    }

    .BestDishCardActionsContainer{
        margin-top: 0px;
        margin-bottom: 0px;
    }

}

