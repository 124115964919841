.OrderScreenAutoSuggest {
    .react-autosuggest__container {
        position: relative;
      }
      
      .react-autosuggest__input {
        border: 1px solid #F7F7F7;
        width: 100%;
        resize: none;
        outline: none;
        background: white !important;
        border-radius: 5px;
        border-color: #F4F4F4;
        outline: none;
        font-family: 'Poppins-Regular';
        font-size: 13px;
        padding: 10px;
      }
    
      .react-autosuggest__input::placeholder {
        color: #9F9F9F;
        padding-top: 2px;
        font-family: 'Poppins-Medium';
        font-size: 12px;
    }
      
      .react-autosuggest__input--focused {
        outline: none;
      }
      
      .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      
      .react-autosuggest__suggestions-container {
        display: none;
      }
      
      .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 45px;
        width: 280px !important;
        max-height: 500px !important;
        background-color: #fff;
        box-shadow: 10px 10px 6px #ECECEC;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        max-height: 100px;
        overflow-y: auto;
        z-index: 2;
        font-family: 'Poppins-Medium';
        font-size: 12px;
        color: grey;
      }
      
      .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
      
      .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
      }
      
      .react-autosuggest__suggestion--highlighted {
        background-color: #F7F7F7;
      }
}