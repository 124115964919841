.daidishWebLogo{
    width: 140px;
}

.daidishMobileLogo{
    width: 50px;
}

div{
  transition: all 0.2s ease-in-out;
}
div[disabled]{
  pointer-events: none;
  opacity: 0.4;
  transition: all 0.2s ease-in-out;
}

span{
    transition: all 0.2s ease-in-out;
  }
span[disabled]{
    pointer-events: none;
    opacity: 0.4;
    transition: all 0.2s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.OrderItemsScroll::-webkit-scrollbar {
    height: 3px;
    width: 8px;
}

.OrderItemsScroll::-webkit-scrollbar-track {
    background: white; 
}

.OrderItemsScroll::-webkit-scrollbar-thumb {
    background: #EFEFEF; 
    border-radius: 10px;
}

.OrderItemsScroll::-webkit-scrollbar-thumb:hover {
background: #888; 
}

.loadingGif{
    position: fixed;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFFFFF;
    height: 100%;
    transition: visibility 2s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 2000ms !important;
}

.leftMenuPane{
    background: #FFFAE5;
    /* box-sizing: content-box;
    flex-grow: 1; */
    transition: all 0.3s ease-in-out;
    border-right: 1px solid #FFDDBD;
}

.rightMenuPane{
    /* display: flex;
    flex-grow: 1; */
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in-out;
}

::-webkit-file-upload-button {
    background: #425FA1;
    color: #FFFFFF;
    border-radius: 5px;
    font-family: "Poppins-Medium";
    border: none;
    padding: 5px 10px;
}
::-webkit-file-upload-button:hover {
    box-shadow: 0px 3px 50px #00000036;
}

.buttonIcon{
    position: relative;
    color: #646464;
    top: 10px;
}

.buttonStyle{
    background : #ffddbd;
    float: right;
    color: #5e5e5e;
    font-size: 12px;
    text-align: center;
    padding-left: 100vh;
}

.buttonStyle:hover{
    color: #979797;
    transition: 0.1s;
    cursor: pointer;
}

.buttonStraight{
    transform: rotate(0deg);
}

.buttonFlip{
    transform: rotate(-180deg);
}

.restoName{
    font-family: 'Bree-SemiBold';
    font-size: 20px;
}

.infoTags{
    font-family: 'Poppins-Medium';
    font-size: 14px;
    color: #EA5B2F;
    margin-bottom: 8px;
}

.infoTags2{
    font-family: 'Poppins-Medium';
    font-size: 12px;
    color: #171725;
    margin: 3px 0px 8px 0px;
}

.restoCompletionStatus{
    padding: 20px 20px 15px 20px;
}

.menuItems{
    float: right;
    height: 35px;
    width: 90%;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
}
.menuItems:hover{
    cursor: pointer;
}

.menuItemsSelected{
    background: #FFFFFF;
    color: #EA5B2F;
    float: right;
    height: 42px;
    padding-top: 5px;
    width: 90%;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-left: 3px solid #FFDDBD;
    transition: all 0.2s ease-in-out;
}
.menuItemsSelected:hover{
    cursor: pointer;
}

.menuIcons{
    padding-top: 3px;
    padding-left: 3px;
    margin-left: 10px;
    transform: translateY(3px);
}

.menuText{
    position: relative;
    top: -3px;
    padding-left: 5px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
}

.infoRibbon{
    background: #2B2624;
    color: #FFFFFF;
    padding: 20px;
}

.infoBanner{
    background: #FFF3C1;
    border-radius: 20px;
}

.bannerImage{
    width: 100px;
    padding-left: 30px;
}

.restoBanner{
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.restoBanner:hover{
    filter: grayscale(20%);
    transition: all 0.3s ease-in-out;
}

.bannerText{
    font-family: 'Bree-Regular';
    font-size: 22px;
    color: #000000;
    position: relative;
}

.stepText{
    font-family: 'Bree-SemiBold';
    position: relative;
    top: -7px;
    color: #2B2624;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
}

.stepText-selected{
    font-family: 'Bree-SemiBold';
    position: relative;
    top: -7px;
    color: #EA5B2F;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
}

.stepHeader, .stepMain{
    font-family: 'Poppins-SemiBold';
    margin-top: 10px;
    position: relative;
    top: 10px;
    left: -36px;
    color: #5F514B;
    width: 83%;
    font-size: 15px;
}

.insightsStepHeader{
    font-family: 'Poppins-SemiBold';
    color: #5F514B;
    font-size: 15px;
    margin-left: 10px;
}

.stepMain{
    position: unset;
    margin-top: 0px;
}

.stepStatus, .stepEdit{
    font-family: 'Poppins-SemiBold';
    font-size: 15px;
    color: #5F514B;
    padding: 0px 30px;
}

.stepEdit{
    transform: translateX(15px);
}

.stepHeadDimension{
    height: 80px;
}

.dishCustomisationAcordionDimension{
    height: 40px;
}

.accordion{
    background: #F5F5F5 !important;
    margin: 15px 0px !important;
    border: 1px solid #D9D9D9;
    border-radius: 5px !important;
    box-shadow: 0px 3px 6px #00000017 !important;
}

.customisationAccordion{
    background: #FFFFFF !important;
    margin: 25px 0px !important;
    /* border: 1px solid #D9D9D9; */
    border-radius: 5px !important;
    box-shadow: 0px 3px 6px #00000017 !important;
}

.accordionSelected{
    background: #FFFFFF !important;
    margin: 15px 0px !important;
    /* border: 1px solid #D9D9D9; */
    border-radius: 5px !important;
    box-shadow: 0px 3px 50px #00000017 !important;
}

.accordionContainer{
    padding-top: 20px;
    padding-bottom: 20px;
}

.restoDescTextArea{
    width: 100%;
    resize: none;
    outline: none;
    background: white;
    border-radius: 5px;
    border: 1px solid #DBDBDB;
    outline: none;
    font-family: 'Poppins-regular';
    font-size: 14px;
    padding: 10px;
    
}
.restoDescTextArea::placeholder {
    color: #9F9F9F;
    padding-top: 2px;
    font-family: 'Poppins-Medium';
    font-size: 12px;
}

.saveButton{
    color: #EB622F !important;
    justify-content: flex-end;
    width: 132px !important;
    font-family: 'Poppins-Medium' !important;
    text-transform: capitalize !important;
    transform: translate(-10px, -25px);
    margin-bottom: -15px !important;
    text-decoration: underline !important;
}
ddSmallButtonSave
.saveSubMenuButton{
    color: white !important;
    justify-content: flex-end;
    width: 132px !important;
    font-family: 'Poppins-Medium' !important;
    text-transform: capitalize !important;
    transform: translate(-10px, -25px);
    margin-bottom: -15px !important;
    text-decoration: underline !important;
}

.dishDescTextArea{
    width: 100%;
    resize: none;
    outline: none;
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    padding: 10px;
}
.dishDescTextArea::placeholder {
    color: #9F9F9F;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

  .doneIcon{
    color: #1AA314;
    transition: all 0.3s ease-in-out;
    background: #BCEABA 0% 0% no-repeat padding-box;
    border-radius: 30px;
    height: 30px;
    display: flex;
    padding: 4px 12px 0px 12px;
    flex-direction: row;
  }

  .pendingIcon{
    color: #D6AD00;
    transition: all 0.3s ease-in-out;
    background: #FAEDB8 0% 0% no-repeat padding-box;
    border-radius: 30px;
    height: 30px;
    display: flex;
    padding: 4px 12px 0px 12px;
    flex-direction: row;
}

  .doneIconText{
      padding-left: 5px;
      font-family: 'Poppins-SemiBold';
      font-size: 14px;
  }

  .doneIconPos{
      font-size: 14px !important;
      transform: translateY(4px);
  }

  .buttonOuterCoating{
    display: inline-block;
    background: #e0e0e0;
    height: 28px;
    border-radius: 20px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 5px;
    box-shadow: inset 0px 2px 5px 1px #929292;
    cursor: pointer;
    z-index: 2;
  }

  .buttonOnOff{
      position: relative;
      background: #DEE8FF;
      color: #425EA1;
      font-family: 'Poppins-bold';
      border-radius: 50%;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      top: -6px;
      box-shadow: -2px 0px 2px 0.5px #aaaaaa;
      transition: all 0.3s ease-in-out;
  }

  .buttonOnOffLogo{
    position: relative;
    background: #DEE8FF;
    color: #425EA1;
    font-family: 'Poppins-bold';
    border-radius: 50%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    top: -12px;
    box-shadow: -2px 0px 2px 0.5px #aaaaaa;
    transition: all 0.3s ease-in-out;
}

.btnLogoPos{
    position: relative;
    top: 5px;
}

.menuGridPosition{
    padding-top: 40px;
    margin-left: -60px;
}

.menuGridPosition2{
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.textBoxStyle, .textBoxWithButtonStyle{
    outline: none;
    padding: 10px;
    font-family: 'Poppins-regular';
    font-size: 14px;
    background: #F4F4F4;
    border-radius: 5px;
    /* border: 1px solid rgb(153, 153, 153);  */
    border: none;
}

.textBoxWithButtonStyle{
    border-radius: 5px 0px 0px 5px;
}

.textBoxStyle::placeholder, .textBoxWithButtonStyle::placeholder{
    color: #9F9F9F;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.editTextBoxStyle{
    outline: none;
    padding: 10px;
    font-family: 'Poppins-regular';
    font-size: 14px;
    background: #FFFFFF;
    border-radius: 5px;
    /* border: 1px solid rgb(153, 153, 153);  */
    border: none;

}

.editTextBoxStyle::placeholder{
    color: #9F9F9F;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.textBoxLabel{
    font-family: 'Poppins-Medium';
    padding-top: 10px;
    font-size: 14px;
}

.editTextBoxLabel{
    font-family: 'Poppins-Regular';
    color: #6C6C6C;
    margin-top: 15px;
}

.errorNote{
    position: relative;
    font-family: 'Poppins-medium';
    font-size: 10px;
    float: right;
    color: #ff0000;
    top: 8px;
}

.wordCounter{
    font-family: 'Poppins-medium';
    font-size: 14px;
    float: right;
    color: #2B2624;
    padding-right: 10px;
}

.packagingSticker{
    position: relative;
    background: #FCD844;
    border-radius: 0px 5px 5px 0px;
    padding: 10.5px 15px 11px 15px;
    top: 8px;
    font-family: 'Poppins-Medium';
    color:#2B2624;
    font-size: 14px;
}

.discountSticker{
    position: relative;
    background: #DEE8FF;
    border-radius: 0px 5px 5px 0px;
    padding: 11px 33% 10.5px 35%;
    margin-left: 0px;
    font-family: 'Poppins-Medium';
    color:#425FA1;
    font-size: 14px;
}

.deliverySticker{
    position: relative;
    background: #DEE8FF;
    top: 20px;
    padding: 5px;
    margin-left: 3px;
    border-radius: 5px;
    font-family: 'Poppins-medium';
}

.discPercBtn{
    color: #425EA1;
    font-family: 'Poppins-Medium';
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.discPercBtnSel{
    background: #425EA1;
    color: #FFFFFF;
    font-family: 'Poppins-Medium';
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.editDishMenuBtnWrapper{
    background: #EFEFEF;
    padding: 13px 3px;
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    transition: all 0.3s ease-in-out ;
}

.editDishMenuSelected{
    background: #FFFFFF;
    padding: 10px 18px;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
}

.editDishMenuUnSelected{
    padding: 10px 18px;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
}

.tableHeader{
    background: #FAFDFF;
    width: 96%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 8px;
    border-radius: 5px;
    border: #e9e9e9 1px solid;
    font-family: 'Poppins-regular';
}

.tableContents{
    background: #FAFDFF;
    width: 96%;
    margin-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;
    border: #e9e9e9 1px solid;
    font-family: 'Poppins-regular';
    transition: all 0.2s ease-in-out ;
}
.tableContents:hover{
    background: #FFFAE5;
    box-shadow: -1px 1px 10px 1px #aaaaaa;
    transition: all 0.2s ease-in-out ;
}

.noOfDishes{
    position: relative;
    top: 10px;
    padding-left: 3px;
}

.dishAvailabilitySwitch{
    position: relative;
    top: -1px;
    left: 10px;
    padding-left: 3px;
}

.inStockText{
    font-family: 'Bree-regular';
    font-size: 20px;
    left: 15px;
    color: #000000;
    position: relative;
}

.viewButton{
    position: relative;
    padding: 5px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out ;
    font-size: 13px;
    top: 8px;
    right: 10px;
    border: #aaaaaa 1px solid;
}
.viewButton:hover{
    background: #EA5B2F;
    border: #EA5B2F 1px solid;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease-in-out ;
}

.dishName{
    position: relative;
    top: 5px;
}
.dishName:hover{
    cursor: pointer;
    text-decoration: underline;
}

.checkBoxPos{
    position: relative;
    top: 3px;
}

.inStockButton{
    background: #e0e0e0;
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    box-shadow: inset 0px 2px 5px 1px #929292;
    cursor: pointer;
}

.inStockSlider{
    position: relative;
    background: #DEE8FF;
    color: #425EA1;
    font-family: 'Poppins-bold';
    border-radius: 50%;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 18px;
    padding-right: 8px;
    top: 0px;
    transition: all 0.3s ease-in-out;
}

.backButton{
    position: relative;
    padding: 5px;
    background: #FFF3C1;
    border-radius: 5px;
    transition: all 0.2s ease-in-out ;
    font-family: 'Poppins-regular';
    font-size: 13px;
    top: -10px;
}
.backButton:hover{
    background: #EA5B2F;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Poppins-regular';
    font-size: 13px;
    transition: all 0.2s ease-in-out ;
}

.newSubMenuOrDish{
    background: #FAFDFF;
    width: 96%;
    margin-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;
    border: #e9e9e9 1px solid;
    font-family: 'Poppins-regular';
    transition: all 0.2s ease-in-out ;
}

.saveButton, .saveSubMenuButton{
    position: relative;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 40%;
    padding-right: 40%;
    border-radius: 5px;
    transition: all 0.2s ease-in-out ;
    font-size: 13px;
    top: 10px;
    background: #e99075;
    color: #FFFFFF;
    width: 100%;
}
.saveButton:hover, .saveSubMenuButton:hover{
    background: #EA5B2F;
    cursor: pointer;
    transition: all 0.2s ease-in-out ;
}

.saveTimeButton{
    position: relative;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    margin-left: -19px;
    transition: all 0.2s ease-in-out ;
    font-size: 13px;
    top: 10px;
    background: #e99075;
    color: #FFFFFF;
}
.saveTimeButton:hover{
    background: #EA5B2F;
    cursor: pointer;
    transition: all 0.2s ease-in-out ;
}

.timingSelect{
    width: 55px;
}

.timingSelectAMPM{
    width: 75px;
}

.newOrders{
    color: #FFFFFF;
    padding: 10px;
    border-radius: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
}

.orderCards{
    border-radius: 10px;
    background: #f8f8f8;
    margin: 8px 0px;
    position: relative;
    transition: all 0.2s ease-in-out ;
}
.orderCards:hover{
    box-shadow: -1px 1px 10px 1px #aaaaaa;
    transition: all 0.2s ease-in-out ;
}

.numBlock{
    color: #FFFFFF;
    text-align: center;
    border-radius: 5px;
    padding-left: 17px;
    padding-right: 17px;
    margin-right: 4px;
    padding-top: 2px;
    font-family: 'Poppins-regular';
}

.orderLabel1{
    font-family: 'Poppins-Medium';
    position: relative;
    font-size: 13px;
    padding: 8px 0px;
}

.orderTime, .customerName, .customerDetails{
    font-family: 'Poppins-regular';
    font-size: 13px;
    opacity: 0.7;
    position: relative;
    padding: 3px 0px;
}

.customerName{
    flex: 1;
}

.customerDetails{
    float: right;
}

.infoText{
    font-size: 10px;
    font-family: 'Poppins-Regular';
    padding: 5px 0px;
}

.leftBtn{
    text-align: center;
    background: #FFFFFF;
    padding: 5px 0px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: #B9B9B9 0.005rem solid;
    cursor: pointer;
    font-family: 'Poppins-regular';
    font-size: 13px;
}
.centerBlock{
    text-align: center;
    background: #FFFFFF;
    padding: 5px 0px;
    border: #B9B9B9 0.005rem solid;
    font-family: 'Poppins-regular';
    font-size: 13px;
}
.rightBtn{
    text-align: center;
    background: #FFFFFF;
    padding: 5px 0px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: #B9B9B9 0.005rem solid;
    cursor: pointer;
    font-family: 'Poppins-regular';
    font-size: 13px;
}

.rejectBtn{
    text-align: center;
    border: #ff0000 1px solid;
    background: #f8f8f8;
    color: #ff0000;
    border-radius: 5px;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
}
.rejectBtn:hover{
    font-size: 14px;
}

.acceptBtn{
    text-align: center;
    border-radius: 5px;
    background: #009828;
    border: #009828 1px solid;
    color: #FFFFFF;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
}
.acceptBtn:hover{
    font-size: 14px;
}

.submitBtn{
    text-align: center;
    border-radius: 5px;
    background: #2B2624;
    border: #2B2624 1px solid;
    color: #FFFFFF;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
}
.submitBtn:hover{
    font-size: 14px;
}

.rejectCard{
    position: absolute;
    top: 25px;
    width: 100%;
    height: 90%;
    border-radius: 5px;
    background: #f8f8f8;
    transition: all 0.2s ease-in-out ;
}

.declineReason{
    border-radius: 5px;
    border: #DBDBDB 1px solid;
    background: white;
    padding: 10px;
    margin-bottom: 2px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
    transition: all 0.1s ease-in-out ;
}

.selectedDeclineReason{
    border-radius: 5px;
    background: #F8BD1C;
    border: #F8BD1C 1px solid;
    color: #FFFFFF;
    padding: 10px;
    margin-bottom: 2px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
    transition: all 0.1s ease-in-out ;
}

.timerIcon{
    position: relative;
    top: 2px;
    padding-right: 3px;
}

.greenTimer{
    text-align: center;
    border-radius: 5px;
    color: #009828;
    background: #DCEFE1;
    border: #009828 1px solid;
    font-family: 'Poppins-regular';
    font-size: 13px;
    padding: 5px 0px;
}

.redTimer{
    text-align: center;
    border-radius: 5px;
    color: #ED8B6E;
    background: #FFE7DD;
    border: #ED8B6E 1px solid;
    font-family: 'Poppins-regular';
    font-size: 13px;
    padding: 5px 0px;
}

.changeTime{
    text-align: right;
    border-radius: 5px;
    color: #C30909;
    font-family: 'Poppins-regular';
    font-size: 13px;
    padding: 5px 0px;
    cursor: pointer;
    text-decoration: underline;
}

.saveNameNum{
    text-align: center;
    border-radius: 5px;
    background: #DEE8FF;
    border: #DEE8FF 1px solid;
    font-family: 'Poppins-regular';
    font-size: 13px;
    padding-top: 1px;
    cursor: pointer;
}
.saveNameNum:hover{
    box-shadow: -1px 1px 6px 1px #aaaaaa;
    transition: all 0.2s ease-in-out ;
}

.orderReadyBtn{
    text-align: center;
    border-radius: 5px;
    background: #A6D01D;
    border: #A6D01D 1px solid;
    color: #FFFFFF;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 8px;
}
.orderReadyBtn:hover{
    font-size: 14px;
    margin-bottom: 7px;
}

.outForDeliveryBtn{
    text-align: center;
    border-radius: 5px;
    background: #71C413;
    border: #71C413 1px solid;
    color: #FFFFFF;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 8px;
}
.outForDeliveryBtn:hover{
    font-size: 14px;
    margin-bottom: 7px;
}

.orderDeliveredBtn{
    text-align: center;
    border-radius: 5px;
    background: #009829;
    border: #009829 1px solid;
    color: #FFFFFF;
    padding: 10px 0px;
    font-family: 'Poppins-regular';
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 8px;
}
.orderDeliveredBtn:hover{
    font-size: 14px;
    margin-bottom: 7px;
}

.deliveryPartnerTextBox{
    outline: none;
    position: relative;
    width: 100%;
    font-family: 'Poppins-regular';
    font-size: 13px;
    top: -1px;
    background: white;
    border-radius: 5px;
    border: none;
    padding: 10px;
}

.deliveryPartnerTextBox::placeholder{
    color: rgb(153, 153, 153);
    font-size: 13px;
}

.editBtn{
    text-align: center;
    border-radius: 5px;
    color: #EA5B2F;
    font-family: 'Poppins-regular';
    font-size: 12px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.fulfilledText{
    text-align: center;
    color: #009828;
    font-family: 'Poppins-bold';
    font-style: italic;
    font-size: 12px;
    padding: 5px;
}

.editImage1{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.switchPosition{
    position: relative;
    top: -39px;
    left: 440px;
}

.popularDishSelectDiv{
    background: #FFFAE5;
    border-radius: 10px;
    padding-bottom: 2px;
    padding-left: 15px;
}

.checkBoxText{
    position: relative;
    font-family: 'Poppins-bold';
    font-size: 13px;
    top: -3px;
    left: -2px;
}

.checkBoxSubText{
    font-family: 'Poppins-regular';
    font-size: 10px;
    position: relative;
    top: -5px;
    left: -2px;
}

.PartnerWithUsBackContainer{
    font-family: "Poppins-Medium"; 
    font-size: 13px;
    transform: translateY(10px);
    background-color: #F2F2F2;
    height: 30px;
    padding: 6px 20px 6px 15px;
    border-radius: 20px;
    cursor: pointer;
    z-index: 200;
}

.PartnerWithUsBackIcon{
    font-size: 14px !important;
    transform: translateY(3px);
}

.SaveProceedText{
    font-family: 'Poppins-SemiBold';
    font-size: 15px;
    text-decoration: underline;
    color: #EB622F;
    padding-right: 25px;
    cursor: pointer;
}

.BestDishDetails{
    color: #464646;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    padding-left: 15px;
}

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    border: 1px solid #F7F7F7;
    width: 100%;
    resize: none;
    outline: none;
    background: #F7F7F7;
    border-radius: 5px;
    border-color: #F4F4F4;
    outline: none;
    font-family: 'Poppins-regular';
    font-size: 14px;
    padding: 10px;
  }

  .react-autosuggest__input::placeholder {
    color: #9F9F9F;
    padding-top: 2px;
    font-family: 'Poppins-Medium';
    font-size: 12px;
}
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 76vw;
    background-color: #fff;
    box-shadow: 10px 10px 6px #ECECEC;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 100px;
    overflow-y: auto;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #F7F7F7;
  }

  .errorText, .successText{
      margin-bottom: 6px;
      color:#EA5B2F;
      font-size: 12px;
      font-family: 'Poppins-Regular';
  }

  .successText{
      color: green;
  }
  
  .radioLabel{
    position: relative;
    font-family: 'Poppins-Regular';
    color: #6C6C6C;
    top: 8px;
    left: -15px;
  }

  .radioLabelSmall{
    position: relative;
    font-family: 'Poppins-Regular';
    color: #3C3C3C;
    top: 8px;
    left: -15px;
    font-size: 14px;
  }

  .vegTag{
    position: relative;
    color: #1D8435;
    border: 1px solid #1D8435;
    left: -20px;
    top: 8px;
  }
  
  .nonVegTag{
    position: relative;
    color: #CB1E33;
    border: 1px solid #CB1E33;
    left: -20px;
    top: 8px;
  }

  .eggTag{
    position: relative;
    color: #C1BB2E;
    border: 1px solid #C1BB2E;
    left: -20px;
    top: 8px;
  }

  .RatingModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    opacity:1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: auto;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.SuccessModalContainer{
    max-width: 500px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.textBoxErrors{
    font-family: 'Poppins-medium';
    font-size: 10px;
    color: #ff0000;
}

.tooltip {
    position: relative;
    display: inline-block;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #FFFFFF;
    color: #5E4B45;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: -19px;
    left: 160%;
    border: #5E4B45 1px dashed;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #5E4B45 transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.ddCustomisationText{
    font-size: 11px;
    /* white-space: pre-wrap; */
    font-family: 'Poppins-Regular';
    line-height: 100%;
    color: #8f8d8d;
}

.lapsedCards{
    background: #ffdddd;
    border: #FF0000 1px solid;
}

.warningCards{
    border: #FF0000 1px solid;
    -webkit-animation: orderCardBlink 2s infinite;  /* Safari 4+ */
    -moz-animation: orderCardBlink 2s infinite;  /* Fx 5+ */
    -o-animation: orderCardBlink 2s infinite;  /* Opera 12+ */
    animation: orderCardBlink 2s infinite;  /* IE 10+, Fx 29+ */
}
@keyframes orderCardBlink {
    0% {background: #F8F8F8;}
    25% {background: #ffdddd;}
    50% {background: #ffdddd;}
    75% {background: #ffdddd;}
    100% {background: #F8F8F8;}
}

.orderInfoBtn{
    color: #EB622F;
    font-size: 28px !important;
    background-color: #EFEFEF;
    padding: 3px;
    border-radius: 0px 10px 0px 10px;
    transition: all 0.2s ease-in-out ;
}
.orderInfoBtn:hover{
    color: #EA5B2F;
    cursor: pointer;
    transition: all 0.2s ease-in-out ;
}

.orderInfoModalContainer{
    max-width: 600px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
    padding: 10px 0px 10px 0px;
}

.customCards{
    background: #FFFFFF;
    width: 100%;
    padding:10px 10px 30px 10px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
}

.ddSmallButtonSave{
    color: #3C3C3C;
    font-size: 14px;
    background: #FCD844;
    border: #FCD844 1px solid;
    font-family: 'Poppins-Medium';
    padding: 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    margin-left: 10px;
}
.ddSmallButtonSave:hover{
    box-shadow: 0px 3px 6px #00000017 !important;
}

.ddprofileTab{
    font-family: "Poppins-Regular";
    padding: 0px 10px;
    cursor: pointer;
    color: #6C6C6C;
}

.ddprofileAvailableData{
    font-family: "Poppins-Regular";
    cursor: pointer;
    color: #6C6C6C;
}

.ddprofileTabSelected{
    font-family: "Poppins-SemiBold";
    color: #EB622F;
    padding: 0px 10px;
    border-bottom: #EB622F 2px solid;
    border-radius: 2px;
    cursor: pointer;
}

.ddSmallButtonCancel{
    color: #3C3C3C;
    font-size: 14px;
    border: #202124 1px solid;
    background: #FFFFFF;
    font-family: 'Poppins-Medium';
    padding: 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    margin-left: 10px;
}
.ddSmallButtonCancel:hover{
    box-shadow: 0px 3px 6px #00000017 !important;
}

.deliveryFixedInput{
    width: 100px;
    height: 30px;
    transform: translateY(18px);
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    text-align: center;
    font-size: 16px;
    color: black;
}

.deliveryHeader{
    margin-top: 10px 0px;
    transform: translateY(5px);
    font-family: 'Poppins-Medium';
    color: #8B8B8B;
}

.cuisineInfoText{
    font-size: 14px;
    font-family: 'Poppins-Regular';
    padding-bottom: 5px;
    color: #6F6F6F;
}

.ddDishCountBadge{
    background: #EA5B2F;
    color: #FFFFFF;
    font-family: 'Poppins-medium';
    font-size: 13px;
    position: absolute;
    display: inline-block;
    width: 90px;
    top: 14px;
    right: 200px;
    border-radius: 50px;
    text-align: center;
    padding: 2px 12px;
}

.OrderTitle{
    font-family: 'Poppins-SemiBold';
    font-size: 20px;
}

.OrderCount{
    color: #6C8CD6;
    font-family: 'Poppins-Regular';
    font-size: 14px;
}

.order-details-left, .order-details-right{
    font-size: 14px;
    margin: 8px 0px;
}

.order-details-left, .summary-header{
    font-family: 'Poppins-Medium';
}

.order-details-right{
    font-family: 'Poppins-Regular';
}

.summary-header{
    font-size: 13px;
}

.summary-details-left, .summary-details-right{
    font-size: 14px;
    font-family: 'Poppins-Regular';
    margin: 6px 0px;
}   

.OrderGrandTotal{
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 20px #0000001D;
    padding: 20px 30px;
}

.OrderGrandTotalHeader{
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
}

.CustomerName{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #626262;
}

.CustomerContact{
    color: #0053D8;
    text-decoration: underline;
    font-size: 14px;
}

.CustomerAddress{
    color: #808080;
    font-size: 12px;
    font-family: 'Poppins-Regular';
}

.CustomerLabel{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #EA5B2F;
}