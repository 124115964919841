.ddFoodSearchDiv{
    border: #E6E6E6 1px solid;
    padding: 5px 8px 8px 10px;
    border-radius: 50px;
  }
  
  .ddSearchIcon{
    color: #EA5B2F;
    top: 5px;
    position: relative;
  }
  
  .ddSearchTextBox{
    color: #1A1A1A;
    position: relative;
    top: -3px;
    outline: none;
    border: none;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    width: 55%;
  }
  .ddSearchTextBox::placeholder {
    color: #9F9F9F;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }

  .ddOrderListBox{
    border: #F5EEEC 1px solid;
    margin-bottom: 10px;
    text-align: left;
    box-shadow: 0px 3px 6px #00000010;
}

.ddDeliveryStatusBox{
    position: relative;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 3px 12px #00000022;
    padding: 20px 40px 20px 40px;
    opacity: 1;
}

.ddStatusSticker{
    color: #FFFFFF;
    font-family: "Poppins-SemiBold";
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 2px;
}

.ddAddCartBtn{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 8px 30px 8px 30px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
  }
  .ddAddCartBtn:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
  }

  .ddFilterTabsContainer{
    display: flex;
    flex-direction: row;
    background-color: #ECECEC;
    border-radius: 2px;
    padding: 1px;
    height: 32px;
  }

  .ddFilterTabs{
    font-size: 12px;
    padding: 6px 8px;
    background-color: white;
    font-family: 'Poppins-Regular';
    border-radius: 2px;
    cursor: pointer;
  }

  .ddFilterTabsSelected{
    font-size: 12px;
    padding: 6px 8px;
    background-color: #425FA1;
    color: white;
    font-family: 'Poppins-Regular';
    border-radius: 2px;
    cursor: pointer;
  }

  .ddFilterDateRangeInput{
    border: transparent;
    width: 70px;
  }

  .ddFilterDateRangeInput:focus {
    outline: none;
  }

  .ddFilterDateRangeInput::placeholder {
    width: 0px;
  }