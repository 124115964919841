@font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat-Medium'), 
            url(../fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Light'), 
          url(../fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Regular'), 
          url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Medium'), 
            url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-SemiBold'), 
            url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Bold'), 
            url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

.ProfileCoverSuperContainer{
    margin-top: 85px;
}

.ProfileCoverContainer{
    background-color: #1F1F1F; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    justify-content: center; 
    align-items: center; 
    height: 22vw; 
    position: relative;
}

.ProfileCoverImage{
    width: 8vw;
    height: 8vw; 
    border-radius: 8vw;
    overflow: hidden; 
    padding: 1vw; 
    background-color: #ECECEC; 
    margin-right: 1vw;
}

.ProfileCoverDescription{
    color: white; 
    margin-left: 1vw;
}

.ProfileCoverName{
    font-family: 'Poppins-Bold';
    font-size: 2.2vw;
}

.ProfileCoverLocation{
    font-family: 'Poppins-Regular';
    font-size: 1.6vw;
}

.ProfileTabsContainer{
    position: absolute; 
    bottom: 0; 
    left: 0; 
    width: 100vw; 
    display: flex; 
    color: white; 
    justify-content: center; 
    font-family: "Poppins-Regular"; 
    font-size: 14px;
}

.ProfileTabs{
    margin: 0px 2vw; 
    text-align: center;
    width: 20vw;
    cursor: pointer;
}

.ProfileTabItem{
    padding-bottom: 6px;
    display: inline-block;
    font-size: 16px;
}

.TabsContentContainer{
    padding: 40px 12vw 150px 12vw;
    background-color: #F9F9F9;
    /* -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95); */
}

.TabLikesContainer, .CuisineSearchBar{
    background-color: white;
    display: flex;
    padding: 10px;
    border-radius: 6px;
    animation: fadeInUp 1s ease-in-out 0s forwards;
    width: calc(100%);
    margin: 10px 0px;
}

.CuisineSearchBar{
    animation: none;
}

@keyframes fadeInUp {
    from { 
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
}

.TabLikesDot{
    height: 6px;
    width: 6px;
    background-color: #3B8D17;
    margin-right: 10px;
    border: 2px solid white;
    box-shadow: 0 0 0pt 2px #3B8D17;
    border-radius: 0.05rem;
    transform: translate(0px, 7px);
}

.TabLikesDescription{
    flex: 1;
}

.TabLikesHeader{
    font-family: 'Poppins-Bold';
    font-size: 14px;
}

.TabLikesDivider{
    font-family: 'Poppins-Regular';
    margin: 0px 10px;
}

.TabLikesCost{
    font-family: 'Poppins-Medium';
    color: #00A73D;
    font-size: 12px;
}

.TabLikesRestaurant{
    color: #FF143B;
    margin-top: 2px;
    font-family: 'Poppins-Regular';
    font-size: 15px;
}

.TabLikesReview{
    padding-right: 1vw;
    font-family: 'Poppins-Light';
    font-size: 14px;
    margin-top: 2px;
}

.TabLikesRate{
    margin: auto 2vw auto 1vw;
    padding: 8px 2.8vw;
    border: 1px solid #464646;
    border-radius: 20px;
}

.AddCuisineInputContainer{
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 6px;
}

.AddCuisineInputIcon{
    position: absolute;
    top: 8px;
    left: 5px;
}

.AddCuisineInput{
    margin: auto;
    width: 30vw;
    border: 0px;
    padding: 10px 10px 10px 30px;  
    border-radius: 40px;
    font-size: 15px;
    color: black;
    font-family: 'Montserrat-Medium';
}

.AddCuisineInput:focus{
    outline: none;
}

.AddCuisineInput::placeholder{
    color: black;
    font-family: 'Montserrat-Medium';
}

.ProfileCuisineItemContainer{
    background-color: #F9F9F9;
    align-items: center; 
    flex-wrap: wrap;
}

.ProfileCuisineItem{
    padding: 8px 15px;
    border: 1px solid #6F6F6F;
    margin: 5px 2px;
    border-radius: 20px;
    /* min-width: 150px; */
    position: relative;
    padding-right: 40px;
    color: #6F6F6F;
    font-family: 'Poppins-Medium';
    font-size: 14px;
}

.ProfileCuisineRemoveIcon{
    position: absolute;
    right: 10px;
    top: 8px;
    margin-left: 10px;
    border-radius: 10px;
    border: 1px solid #6F6F6F;
    padding: 2px 2px 0px 2px;
    font-size: 10px !important;
    cursor: pointer;
    height: 20px;
    transform: translateY(1px);
}

@media (max-width: 500px) {

    .ProfileCoverSuperContainer{
        margin-top: 60px;
    }
    
    .ProfileCoverContainer{
        height: 150px;
        align-items: flex-start; 
        padding-top: 30px;
    }

    .SettingsCoverContainer{
        align-items: center; 
        padding-top: 0px;
    }

    .ProfileCoverImage{
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .ProfileCoverName{
        font-size: 16px;
    }

    .ProfileCoverLocation{
        font-size: 12px;
    }

    .ProfileTabItem{
        font-size: 12px !important;
    }

    .ProfileTabs{
        width: 120px !important;
    }

    .TabsContentContainer{
        padding: 20px 4vw 80px;
    }

    .TabLikesRate{
        margin: 0px 2vw auto 1vw;
        padding: 8px 15px;
    }

    .CuisineSearchBarSuper{
        margin-left: 10px;
        margin-right: 10px;
    }

    .AddCuisineInput{
        width: 70vw;
    }

    .ProfileCuisineItem{
        width: 90vw;
        margin: 12px auto;
    }

}