.newSubMenuOrDish > .MuiGrid-root > .MuiGrid-root > .react-autosuggest__container > .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 61vw;
    background-color: #fff;
    box-shadow: 10px 10px 6px #ECECEC;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 100px;
    overflow-y: auto;
    z-index: 2;
}