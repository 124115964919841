.ddInsightsCardContainer{
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
}

.ddInsightsCard{
    flex: 1;
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 12px 40px #272D3B1D;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    margin: 15px;
    height: 160px;
}

.ddInsightsCardLeftLine{
    height: 130px;
    width: 3px;
    margin-right: 10px;
    border-radius: 2px;
}

.ddInsightsCardLeftCol{
    padding: 2px 9px;
    border-radius: 20px;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    margin-right: 10px;
    height: 25px;
}

.ddInsightsCardRightCol{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.ddInsightsCardHeader{
    margin-top: 2px;
    color: '#2B2624';
    font-family: 'Poppins-Medium';
}

.ddInsightsCardInfo{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ddInsightsCardSubHeader{
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
}

.ddInsightsCardOrdersInfo{
    color: #6C6C6C;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.ddInsightsCardViewDetails{
    color: #425FA1;
    text-decoration: underline;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    cursor: pointer;
}

.insightsTableHeader{
    flex: 1;
    font-size: 12px;
    text-align: center;
    padding: 3px;
    color: #939393;
    font-family: 'Poppins-Medium';
}

.insightsTableCol{
    flex: 1;
    font-size: 14px;
    text-align: center;
    padding: 4px;
    font-family: 'Poppins-Medium';
    color: #2B2624;
    position: relative;
}

.ddDeliveredIcon{
    width: 10px;
    height: 10px;
    background-color: #1F7E1B;
    position: absolute;
    top: 9px;
    left: calc(50% - 50px);
}

.ddSettledIcon{
    position: absolute;
    top: 6px;
    left: calc(50% - 50px);
    font-size: 18px !important;
}